<template>
  <div>
    <Topbar />
    <Navbar />
    <Banner />
    <Body />
    <Footer />
  </div>
</template>

<script>
import Topbar from "@/components/home/Topbar.vue";
import Navbar from "@/components/home/Navbar.vue";
import Banner from "@/components/privacyPolicy/Banner.vue";
import Body from "@/components/privacyPolicy/Body.vue";
import Footer from "@/components/home/Footer.vue";

export default {
  components: {
    Topbar,
    Navbar,
    Banner,
    Body,
    Footer,
  },
};
</script>

<style></style>
