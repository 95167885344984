<template>
  <div>
    <div class="my-bg">
      <b-container>
        <b-row class="d-flex align-items-center justify-content-center">
          <b-col md="12" sm="12" class="py-5 py-xs-0">
            <div class="py-5">
              <div class="">
                <h1 class="font-weight-bolder text-light text-center">
                  Privacy Policy
                </h1>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoveredCol1: false,
      hoveredCol2: false,
      hoveredCol3: false,
      hoveredCol4: false,
    };
  },
  methods: {
    contactPage() {
      this.$router.push({ name: "ContactUs" });
    },
    aboutPage() {
      this.$router.push({ name: "AboutUs" });
    },
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/insurance/header.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.button-container .first-button:hover .logo {
  content: url("~@/assets/images/icons/medical-cross-stroke.svg");
}
.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
