<template>
  <div class="mt-5 mb-5">
    <template>
      <template>
        <b-container>
          <h2 class="py-1">Privacy Policy</h2>
          <p>
            Thank you for choosing MedAsk Tours for your medical tourism needs.
            At MedAsk Tours, our unwavering commitment extends to safeguarding
            your privacy and ensuring the utmost security of your personal
            information. This comprehensive privacy policy delineates the
            methodologies governing the acquisition, utilization, disclosure,
            and safeguarding of your personal information. It is imperative to
            note that the utilization of our services signifies your explicit
            agreement and consent with and adherence to the stipulations set
            forth in this policy. Your compliance serves as a fundamental aspect
            of our mutual commitment to maintaining the highest standards of
            privacy and security in the context of our services.
          </p>

          <h2>1. Information We Collect</h2>
          <p>We may collect the following types of personal information:</p>
          <ul>
            <li>
              <strong>Contact Information:</strong>
              Name, address, phone number, email address, and other relevant
              contact details.
            </li>
            <li>
              <strong> Medical Information:</strong> Information related to your
              medical history, treatments, and health conditions.
            </li>
            <li>
              <strong>Financial Information:</strong> Payment information,
              billing details, and insurance information.
            </li>
            <li>
              <strong>Identification Information:</strong> Passport details,
              government-issued identification numbers, and other relevant
              identification information.
            </li>
            <li>
              <strong>Demographic Information:</strong> Age, gender,
              nationality, and other demographic details.
            </li>
            <li>
              <strong>Communication Information:</strong> Records of your
              correspondence with us, including emails, chat messages, and phone
              calls.
            </li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use your personal information for the following purposes:</p>
          <ul>
            <li>
              Facilitating Medical Services: To arrange and coordinate medical
              treatments, appointments, and related services.
            </li>
            <li>
              Communication: To communicate with you regarding your medical
              tourism arrangements, updates, and other relevant information.
            </li>
            <li>
              Billing and Payments: To process payments, issue invoices, and
              manage financial transactions related to our services.
            </li>
            <li>
              Legal Compliance: To comply with applicable laws, regulations, and
              legal processes.
            </li>
          </ul>

          <h2>3. Information Sharing and Disclosure</h2>
          <p>We may share your personal information with:</p>
          <ul>
            <li>
              Medical Service Providers: Hospitals, clinics, and healthcare
              professionals involved in your medical treatment.
            </li>
            <li>
              Business Partners: Third-party service providers and partners
              involved in facilitating your medical tourism experience.
            </li>
            <li>Legal Authorities: When required by law.</li>
          </ul>

          <h2>4. Security Measures</h2>
          <p>
            We implement reasonable security measures to protect your personal
            information from unauthorized access, disclosure, alteration, and
            destruction. However, no data transmission over the internet or
            electronic storage method is entirely secure, and we cannot
            guarantee absolute security.
          </p>

          <h2>5. Your Choices and Rights</h2>
          <p>
            You have the right to access, correct, or delete your personal
            information. You may also choose to opt-out of receiving promotional
            communications from us.
          </p>

          <h2>6. Changes to this Privacy Policy</h2>
          <p>
            We may update this privacy policy periodically. Any changes will be
            posted on our website with the updated effective date.
          </p>

          <h2>7. Contact Us</h2>
          <p>
            If you have any questions or concerns about our privacy practices,
            please contact us at [0092-51-111-633-275].
          </p>
        </b-container>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
h1 {
  font-weight: bolder;
  color: #091e3d;
}
h2 {
  font-weight: bold;
  color: black;
}
p {
  font-weight: normal;
  color: #525252;
}
strong {
  font-weight: bold;
  color: #06bd46;
}
</style>
